import React, { createContext, useState, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import {
    PRIMARY_COLOR,
    BLACK_LISTED_COLOR,
    WHITE_LISTED_COLOR,
    CONFIRM_BUTTON_COLOR,
} from '../constants/styleConstants';

// Define light and dark themes
const lightTheme = createTheme({
    typography: {
        fontFamily: ['Montserrat'].join(','),
        fontSize: 12,
    },
    palette: {
        mode: 'light',
        primary: { main: PRIMARY_COLOR },
        whitelisted: { main: WHITE_LISTED_COLOR },
        blacklisted: { main: BLACK_LISTED_COLOR },
        confirmButton: { main: CONFIRM_BUTTON_COLOR, contrastText: 'white' },
    },
});

const darkTheme = createTheme({
    typography: {
        fontFamily: ['Montserrat'].join(','),
        fontSize: 12,
    },
    palette: {
        mode: 'dark',
        primary: { main: PRIMARY_COLOR },
        whitelisted: { main: WHITE_LISTED_COLOR },
        blacklisted: { main: BLACK_LISTED_COLOR },
        confirmButton: { main: CONFIRM_BUTTON_COLOR, contrastText: 'white' },
    },
});

// Create a context
const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeToggleProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleTheme = () => setIsDarkMode((prev) => !prev);

    const theme = isDarkMode ? darkTheme : lightTheme;

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};