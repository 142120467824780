import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Outlet} from 'react-router-dom';
import NavBarItems from "./navbarItems/NavBarItems";
import Header from './header/Header';
import styles from './NavbarLayout.module.scss'
import {
    MENU_CLOSER_ICON_TEST_ID,
    MENU_HEADER_TEST_ID,
    NAVIGATION_NETWORK_LOGO_URL_TEST_ID
} from "../../utils/testUtils";

const drawerWidth = 230;

const EXTRA_DRAWER_WIDTH = '13px';

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + ${EXTRA_DRAWER_WIDTH})`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const networkLogoURL = "https://storage.googleapis.com/turkishclicks.appspot.com/1/2019/11/influio_slim.png";

export default function NavbarLayout() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Header
                open={open}
                inputProps={{
                    'data-testid': MENU_HEADER_TEST_ID,
                    'className': open ? 'opened' : 'closed'
                }}
                handleDrawerOpen={handleDrawerOpen}
                drawerWidth={drawerWidth}
                AppBar={AppBar}
            />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader data-testid={MENU_CLOSER_ICON_TEST_ID}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                {networkLogoURL && <div className={styles.logoHolder}>
                    <img
                        className={styles.networkLogo}
                        src={networkLogoURL}
                        alt={NAVIGATION_NETWORK_LOGO_URL_TEST_ID}/>
                </div>}
                <NavBarItems open={open} setOpen={setOpen}/>
            </Drawer>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3}}
                className={styles.contentContainer}>
                <DrawerHeader/>
                <Outlet/>
            </Box>
        </Box>
    );
}