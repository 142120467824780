import React from 'react';
import styles from "../BrandResults/BrandResults.module.scss";
import GenericTable from "../../../common/table/GenericTable";
import ProductList from "../ProductResults/ProductList";
import {formatTurkishLira} from "../../../common/utils";

const BrandResults = ({brands,productIdQuantityMap}) => {

    const brandColumns = {};
    brandColumns.visibility = {}
    brandColumns.data = [
        {
            accessorKey: 'brandName',
            header: "Brand Name",
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'totalAmount',
            header: "Total amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.totalAmount)}</div>
            )
        }
    ];


    const expandBrands = (cell) => {
        const products = cell.row.original.products;
        //check any of the element type is string
        const hasString = products.some(element => typeof element === "string");

        if (!hasString) {
            const mappedProducts = cell.row.original.products.map(product => {
                return {
                    ...product,
                    quantity:productIdQuantityMap[product.client_product_id],
                    productId: product.client_product_id,
                    productInfo: product
                }
            }).sort((a, b) => b.quantity - a.quantity);
            return (<ProductList products={mappedProducts}/>)
        }else{
            const stringProducts = cell.row.original.products.map(product => {
                return {
                    client_product_id: product,
                    productId:product,
                    quantity:productIdQuantityMap[product],
                    productInfo: {
                        client_product_id: product,
                        image: "",
                        title: "",
                        category_id: "",
                        category_name: "",
                        price: 0,
                        link: ""
                    }
                }
            }).sort((a, b) => b.quantity - a.quantity);
            return (<ProductList products={stringProducts}/>)
        }
    }

    return (
        <div className={styles.products}>
            {!!brands && <GenericTable
                outputColumns={brandColumns}
                tableData={brands}
                rowCount={brands.length}
                enablePagination={true}
                allRowsAreExpanded={brandColumns.length === 1}
                expand={expandBrands}
            />}
        </div>
    );
};

export default BrandResults;
