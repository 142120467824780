import React, {useEffect, useState} from 'react';
import styles from "./NavbarItems.module.scss";
import ListItem from "@mui/material/ListItem";
import {NavLink, useLocation} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {useTranslation} from "react-i18next";
import {NAVBAR_ITEM_TOOLTIP, NAVIGATION_SIDEMENU_ITEMS_TEST_ID} from "../../../utils/testUtils";
import {navbarItems} from "../../../common/utils";
import {Avatar, Tooltip} from "@mui/material";

import {
    ADMIN_PAID_PROJECTS,
    ADVERTISER_MANAGEMENT,
    AFFILIATE_PERFORMANCE,
    AI_CHAT_ASSISTANT,
    AI_GROUP,
    AI_INFLUENCER_REVENUES,
    CREATE_CAMPAIGN,
    CREATE_PAID_PROJECT,
    CREATE_PROMO_CODE,
    HANDLE_MISSING_OLD_HTML_PAGES,
    INSERT_INFLUENCERS,
    INTEGRATIONS_HELPER,
    LCW_NEW_PARTNERS,
    LOGOUT,
    PURCHASE_REPORTS,
    SIGN_UP,
    STATUS_CENTER,
    USER_PAYMENTS,
    USERS_ADMIN,
    USERS_ADMIN_LIST
} from "../../../common/constants/routes";
import useLogout from "../../../common/hooks/useLogout";
import {useSelector} from "react-redux";
import {
    AddCircleOutlined, AssessmentOutlined,
    AttachMoneyOutlined,
    BrushOutlined,
    DiscountOutlined, Face2Outlined,
    GamesOutlined,
    HtmlOutlined, Instagram,
    IntegrationInstructionsSharp,
    LogoutOutlined,
    ManageAccountsOutlined,
    NotificationsActiveOutlined,
    PaidOutlined,
    QueryStatsOutlined,
    RadarOutlined
} from "@mui/icons-material";
import lcwLogo from "../../../images/lcw.svg"
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const openNestedMenuIndicators = {
    "user-admin.title-users-main-title": false,
    "menu-item.adv_management": false,
    "menu-item.ai-radar": false,
}

const NavBarItems = ({open, setOpen}) => {
    const [openNestedMenu, setOpenNestedMenu] = React.useState(openNestedMenuIndicators);

    const handleNestedMenuClick = (name) => {
        setOpen(true)
        setOpenNestedMenu({...openNestedMenu, [name]: !openNestedMenu[name]});
    };

    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {logout} = useLogout();
    const [currentNavbarItems, setCurrentNavbarItems] = useState(null);
    const isMasterPassword = useSelector(state => state.auth?.user?.isMasterPassword);
    const user = useSelector(state => state.auth?.user.user);
    useEffect(() => {
        if (!!isMasterPassword) {



            const usersAdminExist = navbarItems.find(item => item.path === USERS_ADMIN);
            if (!usersAdminExist) {
                const userAdminObject = {
                    name: 'user-admin.title-users-main-title',
                    path: USERS_ADMIN,
                    icon: <Face2Outlined/>,
                    subItems: [{
                        name: 'user-admin.title-users',
                        path: USERS_ADMIN_LIST,
                        icon: <ManageAccountsOutlined/>
                    }, {
                        name: 'menu-item.create-new-user',
                        path: SIGN_UP,
                        icon: <AddCircleOutlined/>
                    }, {
                        name: 'menu-item.user-payments',
                        path: USER_PAYMENTS,
                        icon: <AttachMoneyOutlined/>
                    }, {
                        name: 'menu-item.affiliates-performances',
                        path: AFFILIATE_PERFORMANCE,
                        icon: <AssessmentOutlined/>
                    }, {
                        name: 'menu-item.create-new-pages',
                        path: HANDLE_MISSING_OLD_HTML_PAGES,
                        icon: <HtmlOutlined/>
                    }]

                }
                navbarItems.push(userAdminObject);
            }


            const paidProjects = navbarItems.find(item => item.path === ADMIN_PAID_PROJECTS);
            if (!paidProjects) {
                const paidObject = {
                    name: 'paid-projects.main-title',
                    path: ADMIN_PAID_PROJECTS,
                    icon: <PaidOutlined/>
                }
                navbarItems.push(paidObject);
            }


            const aiRadar = navbarItems.find(item => item.path === AI_GROUP);
            if (!aiRadar) {
                const aiGroup = {
                    name: 'menu-item.ai-instagram',
                    path: AI_GROUP, icon: <Instagram/>,
                    subItems: [
                        {
                            name: 'menu-item.ai-radar',
                            path: AI_CHAT_ASSISTANT,
                            icon: <RadarOutlined/>
                        }, {
                            name: 'menu-item.ai-influencer-revenues',
                            path: AI_INFLUENCER_REVENUES,
                            icon: <AttachMoneyOutlined/>
                        }, {
                            name: 'menu-item.insert-influencers',
                            path: INSERT_INFLUENCERS,
                            icon: <AddCircleOutlined/>
                        }]
                }
                navbarItems.push(aiGroup);
            }

        }
        const purchaseReports = navbarItems.find(item => item.path === PURCHASE_REPORTS);
        if (!purchaseReports) {
            const purchaseObject = {
                name: 'menu-item.purchase_reports', path: PURCHASE_REPORTS, icon: <QueryStatsOutlined/>
            }
            navbarItems.push(purchaseObject);
        }


        const advManagerExist = navbarItems.find(item => item.path.includes(ADVERTISER_MANAGEMENT));
        if (!advManagerExist) {
            const advManager = {
                name: 'menu-item.adv_management',
                path: ADVERTISER_MANAGEMENT,
                icon: <GamesOutlined/>, subItems: [
                    {
                        name: 'menu-item.create-campaign',
                        path: CREATE_CAMPAIGN,
                        icon: <BrushOutlined/>
                    },
                    {
                        name: 'menu-item.create-promo-code',
                        path: CREATE_PROMO_CODE,
                        icon: <DiscountOutlined/>
                    }, {
                        name: 'menu-item.create-paid-project',
                        path: CREATE_PAID_PROJECT,
                        icon: <PaidOutlined/>
                    },
                    {
                        name: 'menu-item.status-center',
                        path: STATUS_CENTER,
                        icon: <NotificationsActiveOutlined/>
                    }]
            }
            navbarItems.push(advManager);
        }


        if (user.offer_id_list.includes("132") || isMasterPassword) {
            const lcwPartnersExist = navbarItems.find(item => item.path === LCW_NEW_PARTNERS);
            if (!lcwPartnersExist) {
                const lcwObject = {
                    name: 'menu-item.lcw-new-partners',
                    path: LCW_NEW_PARTNERS,
                    icon: <Avatar src={lcwLogo} alt={lcwLogo}/>

                }
                navbarItems.push(lcwObject);
            }
        }


        const integrationHelper = navbarItems.find(item => item.path === INTEGRATIONS_HELPER);
        if (!integrationHelper) {
            const integrationHelperObject = {
                name: 'menu-item.integration_helper', path: INTEGRATIONS_HELPER, icon: <IntegrationInstructionsSharp/>
            }
            navbarItems.push(integrationHelperObject);
        }

        const logout = navbarItems.find(item => item.path === LOGOUT);
        if (!logout) {
            const logoutObject = {
                name: 'menu-item-logout', path: LOGOUT, icon: <LogoutOutlined/>
            }
            navbarItems.push(logoutObject);
        }

        setCurrentNavbarItems(navbarItems);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return (<List data-testid={NAVIGATION_SIDEMENU_ITEMS_TEST_ID}>
        {!!currentNavbarItems && currentNavbarItems.map((item) => (<Tooltip
            data-testid={NAVBAR_ITEM_TOOLTIP}
            title={open ? null : t(item.name)}
            arrow
            placement="right"
            key={item.name}
        >
            <ListItem
                key={item.name}
                disablePadding
                sx={{display: 'block', mt: 1}}
            >
                {(!item.subItems || item.subItems.length === 0) && <div
                    className={pathname.includes(item.path) ? styles.selectedItem : styles.notSelectedItem}>
                    {<NavLink
                        key={item.name}
                        data-testid={item.name}
                        onClick={() => {
                            if (item.path === LOGOUT) logout()
                        }}
                        to={item.path === LOGOUT ? false : item.path}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 30, justifyContent: open ? 'initial' : 'center', px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    maxWidth: 10,
                                    mr: open ? 3 : 2,
                                    justifyContent: 'flex-start',
                                }}
                                className={styles.icon}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.itemText}
                                primary={t(item.name)}
                                sx={{display: open ? "block" : "none"}}
                            />
                        </ListItemButton>
                    </NavLink>}
                </div>}
                {(!!item.subItems && item.subItems.length > 0) && <div>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        handleNestedMenuClick(item.name);
                    }}>
                        <ListItemIcon sx={{marginLeft: open ? 0.6 : 1.2}}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText className={styles.itemText} sx={{
                            display: open ? "block" : "none",
                            ml: open ? -2.5 : 0,
                        }}
                                      primary={t(item.name)}/>
                        {openNestedMenu[item.name] ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse sx={{display: open ? "block" : "none"}} in={openNestedMenu[item.name]} timeout="auto"
                              unmountOnExit>
                        <List component="div" disablePadding>
                            {item.subItems.map((subItem) => (<div
                                className={pathname.includes(subItem.path) ? styles.selectedItem : styles.notSelectedItem}
                                key={subItem.name}>
                                <NavLink
                                    key={subItem.name}
                                    data-testid={subItem.name}
                                    onClick={() => {
                                        if (item.path === LOGOUT) logout()
                                    }}
                                    to={item.path === LOGOUT ? false : `${item.path}${subItem.path}`}
                                >
                                    <ListItemButton sx={{pl: 3}}>
                                        <ListItemIcon className={styles.icon}>
                                            {subItem.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            className={styles.itemText}
                                            primary={t(subItem.name)}
                                            sx={{
                                                color: "#202020", marginLeft: -2, fontSize: 11
                                            }}/>
                                    </ListItemButton>
                                </NavLink>
                            </div>))}
                        </List>
                    </Collapse>
                </div>}
            </ListItem>

        </Tooltip>))}
    </List>);
}

export default NavBarItems;